<template>
	<v-layout class="btx-time-picker">
		<v-flex md6>
			<AutoCompleteInput
				time-picker
				:hide-details="hideDetails"
				:items="hourList"
				:disabled="disabled"
				:hide-top-margin="hideTopMargin"
				:loading="loading"
				v-model="hour"
				append-icon=""
				prepend-inner-icon="mdi-clock-time-four-outline"
				placeholder="Hour"
				class="hour-picker"
				v-on:change="emitValue()"
			></AutoCompleteInput>
		</v-flex>
		<v-flex md6>
			<AutoCompleteInput
				time-picker
				:hide-details="hideDetails"
				:hide-top-margin="hideTopMargin"
				:items="minuteList"
				:disabled="disabled"
				:loading="loading"
				v-model="minute"
				append-icon=""
				placeholder="Minute"
				class="minute-picker"
				v-on:change="emitValue()"
			></AutoCompleteInput>
		</v-flex>
	</v-layout>
</template>
<script>
import { padStart, toSafeInteger } from "lodash";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import MomentJS from "moment-timezone";

MomentJS.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
	name: "time-input",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		hideTopMargin: {
			type: Boolean,
			default: false,
		},
		value: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: null,
		},
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		AutoCompleteInput,
	},
	data() {
		return {
			hourList: [],
			minuteList: [],
			hour: 1,
			minute: 1,
		};
	},
	watch: {
		value() {
			this.init();
		},
	},
	methods: {
		init() {
			if (!this.value) {
				return false;
			}
			const time = this.value.split(":");
			this.hour = toSafeInteger(time[0]);
			this.minute = toSafeInteger(time[1]);
			this.emitValue();
		},
		emitValue() {
			const time = MomentJS();
			time.hours(this.hour);
			time.minutes(this.minute);
			const output = time.format("HH:mm");
			if (output != this.value) {
				this.$emit("input", output);
				this.$emit("change", output);
			}
		},
	},
	mounted() {
		for (let i = 1; i < 23; i++) {
			this.hourList.push({ text: padStart(i, 2, "0"), value: i });
		}
		for (let z = 1; z < 60; z++) {
			this.minuteList.push({ text: padStart(z, 2, "0"), value: z });
		}

		this.minute = MomentJS().format("mm");
		this.hour = MomentJS().format("HH");

		this.$nextTick(() => {
			this.init();
			this.emitValue();
		});
	},
};
</script>
